import { configureStore, combineReducers, compose } from '@reduxjs/toolkit';
import reduxDynamicMiddlewares from './lib/redux-dynamic-middlewares';
import routingReducer, { refreshCurrentPath } from './ducks/routing';
import sessionReducer, { refreshLoggedIn } from './ducks/session';
import appReducer, { setVersion } from './ducks/app';

const createRootReducer = (dynamicReducers = {}) => combineReducers({
  routing: routingReducer,
  session: sessionReducer,
  app: appReducer,
  ...dynamicReducers,
});

const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(reduxDynamicMiddlewares),
});

store.reducers = {};

store.actionDispatchers = window.reduxState?.store?.actionDispatchers || {};

const injectAsyncReducers = (asyncReducers) => {
  store.reducers = { ...store.reducers, ...asyncReducers };
  store.replaceReducer(createRootReducer(store.reducers));
};

const addActionDispatchers = (namespace, actions) => {
  Object.entries(actions).forEach(([key, value]) => {
    store.actionDispatchers[namespace] = store.actionDispatchers[namespace] || {};
    store.actionDispatchers[namespace][key] = compose(store.dispatch, value);
  });
};

window.reduxActionDispatchers = store.actionDispatchers;

addActionDispatchers('routing', { refreshCurrentPath });
addActionDispatchers('session', { refreshLoggedIn });
addActionDispatchers('app', { setVersion });

window.reduxState = {
  store,
  addReducers: injectAsyncReducers,
  addActionDispatchers,
};

export {
  store,
  injectAsyncReducers as injectReducers,
};
